export const PRINCIPAL_LIST = ['乙方', '甲方', '丙方', '丁方', '戊方', '己方', '庚方', '辛方', '壬方', '癸方']

export const PROOF_TYPE = ['区块链存证证明', '签署文件保全证明']

export const PROOF_STATUS = ['未出证', '已出证', '出证中']

export const UNIT = ['次', '份', '个', 'G', '分钟', '页', '人', '篇']

export const VISIT_TYPE = ['其它', '陪同谈判', '参与重大会议', '处理纠纷', '重大合同拟定']

export const ROSTER_STATUS = ['离职', '待入职', '试用期', '已转正']

export const ROSTER_TYPE = ['全职', '实习', '返聘', '劳务', '外包', '顾问']

export const FIELD_TYPE = ['-', '单行文本', '多行文本', '单选项', '多选项', '下拉选项', '日期', '单文件', '多文件', '地区框', '民族框', '籍贯框', '身份证框', '手机号框', '邮箱框', '数字框', '部门选择', '成员选择']

export const HR_CONTRACT_TYPE = ['劳动合同（固定期限）', '劳动合同（无固定期限）', '实习协议', '劳务合同', '外包协议', '退休返聘协议', '顾问合同']

export const CONTRACT_SERVICE_TYPE = ['文书模板', '合同拟定', '合同审核', '合同修改', '函件拟定', '制度制定', '函件审核', '制度审核']

export const NATION_DATA = [
  '汉族',
  '蒙古族',
  '回族',
  '藏族',
  '维吾尔族',
  '苗族',
  '彝族',
  '壮族',
  '布依族',
  '朝鲜族',
  '满族',
  '侗族',
  '瑶族',
  '白族',
  '土家族',
  '哈尼族',
  '哈萨克族',
  '傣族',
  '黎族',
  '傈僳族',
  '佤族',
  '畲族',
  '高山族',
  '拉祜族',
  '水族',
  '东乡族',
  '纳西族',
  '景颇族',
  '柯尔克孜族',
  '土族',
  '达斡尔族',
  '仫佬族',
  '羌族',
  '布朗族',
  '撒拉族',
  '毛南族',
  '仡佬族',
  '锡伯族',
  '阿昌族',
  '普米族',
  '塔吉克族',
  '怒族',
  '乌孜别克族',
  '俄罗斯族',
  '鄂温克族',
  '德昂族',
  '保安族',
  '裕固族',
  '京族',
  '塔塔尔族',
  '独龙族',
  '鄂伦春族',
  '赫哲族',
  '门巴族',
  '珞巴族',
  '基诺族'
]
