<template>
  <div :class="['box-svg', iconClass]" v-on="$listeners" v-html="svgContent"></div>
</template>

<script>
const catchSvg = {}
const req = require.context('@/assets/svg-icon', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map((key) => {
  return (catchSvg[key] = requireContext(key))
})
requireAll(req)
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    }
  },
  computed: {
    iconName () {
      return `#icon-${this.iconClass}`
    },
    svgClass () {
      if (this.iconClass) {
        return 'icon-' + this.iconClass
      } else {
        return 'icon'
      }
    },
    svgContent () {
      if (catchSvg) {
        return catchSvg[`./${this.iconClass}.svg`]?.default.content.replace(/symbol/g, 'svg')
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped lang="less">
.box-svg {
  width: 20px;
  height: 20px;
  box-sizing: content-box;
  display: inline-block;
  line-height: normal;
  min-width: 1em;
  min-height: 1em;
  //vertical-align: -0.15em;
  vertical-align: middle;
  fill: currentColor;
  overflow: hidden;
  /deep/ svg {
    display: block;
  }
}
svg {
  width: 100%;
  height: 100%;
}
/*.svg-icon {
  width: inherit;
  height: inherit;
}*/

</style>
