<template>
  <div class="ft-frame">
    <div class="header">
      <div class="logo"></div>
      <div class="nav">
        <el-scrollbar style="width: 100%;" :wrap-style="{ width: '100%', overflowX: 'auto' }">
          <div
            v-for="item in menus"
            :key="item.path"
            class="nav-but"
            :class="{ active: item.path === currentTopMenuPath }"
            @click="goto(item)"
          >
            {{ item.title }}
          </div>
        </el-scrollbar>
      </div>
      <div class="tools">
        <div class="tools-btn" v-if="currentOrga" >
          <span class="company-name"  @click="gotoRoleSelect()"><span style="color: #3BFF7B">●</span> {{ currentOrga.name }} </span>
        </div>
        <div class="tools-btn" v-if="!currentOrga && hasOtherIdentity" >
          <span class="company-name"  @click="gotoRoleSelect()">切换企业身份</span>
        </div>
        <div class="tools-btn help" v-if="currentOrga"  @click="gotoHelp">
          <img :src="helpImage">
          <span>指引</span>
        </div>
        <div class="tools-btn">
          <el-popover
            placement="bottom"
            popper-class="frame-popup-menu w"
            width="200"
            trigger="hover"
          >
            <div class="kefu">
              <div class="kefu-1">服务热线</div>
              <div class="kefu-2">400-168-5886</div>
              <div class="kefu-split"></div>
              <div class="kefu-3">客服企业微信</div>
              <div class="kefu-4">微信扫码添加客服咨询</div>
              <div class="kefu-5">
                <img :src="require('@/assets/images/kefu_qrcode.png')">
              </div>
            </div>
            <i slot="reference" class="iconfont icon-kefu message"></i>
          </el-popover>
        </div>
        <div class="tools-btn" @click="gotoMessage">
          <el-badge :value="unreadMessageCount" class="badge">
            <i class="iconfont icon-xiaoxiicon message"></i>
          </el-badge>
        </div>
        <div class="tools-btn">
          <div class="split"></div>
        </div>
        <div class="tools-btn">
          <img
            class="avater"
            :src="userInfo.info.img ? userInfo.info.img : defaultAvater"
          />
          <img
            class="auth-icon"
            :src="userInfo.info.auth ? authImage : unAuthImage"
          />
        </div>
        <div class="tools-btn" style="margin-left: 10px">
          <el-popover
            placement="bottom"
            popper-class="frame-popup-menu"
            width="200"
            trigger="click"
          >
            <div class="top-menus">
              <div class="frame-popup-menu-info">
                <div class="avater">
                  <img
                    :src="userInfo.info.img ? userInfo.info.img : defaultAvater"
                  />
                </div>
                <div class="name">
                  {{ userInfo.info.trusName }}
                </div>
                <div class="company" v-if="userInfo?.current?.typeId === 2">

                  <span>{{ userInfo?.current?.name }}</span>
                  <span class="auth-info" style="margin-left:8px;line-height: 1.5;" :class="{ yes: currentOrga?.authStatus }">
                    <i
                      class="iconfont icon"
                      :class="
                        currentOrga?.authStatus
                          ? 'icon-yirenzhengicon'
                          : 'icon-weirenzhengicon'
                      "
                    />
                    {{ currentOrga?.authStatus | authStatus }}
                  </span>
                </div>
                <div class="company" v-if="userInfo?.current?.typeId === 1">
                  法图号: {{ userInfo?.info?.account }}
                </div>
              </div>
              <div
                class="frame-popup-menu-item"
                @click="$router.push('/system/account/secure')"
              >
                <i class="iconfont icon-gerenxinxiicon" />
                <span>个人认证</span>
                <span class="auth-info" style="float:right;" :class="{ yes: userInfo.info.auth }">
                  <i
                    class="iconfont icon"
                    :class="
                      userInfo.info.auth
                        ? 'icon-yirenzhengicon'
                        : 'icon-weirenzhengicon'
                    "
                  />
                  {{ userInfo.info.auth | authStatus }}
                </span>
              </div>
              <div
                v-if="switchBtnVisable"
                class="frame-popup-menu-item"
                @click="gotoRoleSelect()"
              >
                <i class="iconfont icon-qiehuanzhanghaoicon" />
                <span>切换身份</span>
              </div>
              <div class="frame-popup-menu-item" @click="logout">
                <i class="iconfont icon-tuichuicon" />
                <span>退出</span>
              </div>
            </div>
            <span
              slot="reference"
              style="display: block;text-align: right;cursor: pointer;white-space: nowrap;height: 50px;"
            >
              <span
                style="
                  vertical-align: middle;
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden;
                  max-width: 80px;
                  text-overflow: ellipsis;
                "
              >
                {{ userInfo.info.trusName }}
              </span>
              <i
                style="vertical-align: middle; margin-left: 10px"
                class="el-icon-caret-bottom"
              ></i>
            </span>
          </el-popover>
        </div>
      </div>
    </div>
    <div class="main">
      <div v-show="showMenu" class="menus">
        <el-scrollbar style="height:100%" class="hx">
        <ul>
          <li v-for="item in submenus" :key="item.currentMenuPath">
            <div
              class="menu-item"
              @click="goto(item)"
              :class="{ active: currentMenuPath === item.path }"
            >
              <i
                :class="item.icon"
                class="iconfont menu-item-icon"
                :style="{ color: item.iconColor }"
              ></i>
              <label class="label">{{ item.title }}</label>
              <i
                v-if="item.children && item.children.length"
                class="el-icon-arrow-down"
                :class="{ expand: item.children && item.children.length > 0 }"
              ></i>
            </div>
            <ul v-if="item.children && item.children.length > 0">
              <li v-for="subitem in item.children" :key="subitem.path">
                <div
                  class="menu-item"
                  @click="goto(subitem)"
                  :class="{ active: currentMenuPath === subitem.path }"
                >
                  <i class="icon"></i>
                  <label class="label">{{ subitem.title + '' }}</label>
                  <i class="expand"></i>
                  <template v-if="subitem.badge">
                    <span class="badge" :class="{ zero: !badge[subitem.badge] }">{{ badge[subitem.badge] || '0' }}</span>
                  </template>
                </div>
              </li>
            </ul>
          </li>
        </ul>
        </el-scrollbar>
      </div>
      <div class="content" :style="{ padding: showMenu ? '20px' : '0px'}">
        <div class="tip" v-if="!userInfo?.current?.status">
          <i class="el-icon-warning" />
          <label>
            为防范支付风险，保护您的账户安全，结合相关法律规定，请在购买服务前完成实名认证。
          </label>
          <label
            style="color: #5c8afa; text-decoration: underline; cursor: pointer"
            @click="gotoAuthPath(userInfo?.current?.typeId)"
          >
            立即认证
          </label>
        </div>
        <div class="inside">
          <router-view v-if="isShow" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import defaultAvater from '@/assets/images/avater.png'
import routerMain from '@/router/router.main.js'
import MainService from '@/service/main.js'
import { permission } from '@ft/common-library'
import Server from '@/apis'
import axios from 'axios'
import authImage from '@/assets/images/account/auth/已认证_白边2x.png'
import unAuthImage from '@/assets/images/account/auth/未认证_白边2x.png'
import helpImage from '@/assets/images/指引@2x.png'
const { routerList } = routerMain
export default {
  data () {
    return {
      isShow: false,
      badge: {
        serviceCount: 0,
        sealReviewCount: 0, // 待用印审批数
        contractReviewCount: 0, // 待合同审批数
        contractSignCount: 0, // 合同签署数
        waitConfirmation: 0, // 待转正人数
        waitEntry: 0, // 待入职人数
        waitResign: 0 // 待离职人数
      },
      unreadMessageCount: 0,
      authImage,
      unAuthImage,
      helpImage,
      showMenu: true,
      userInfo: this._auth.userInfo,
      defaultAvater,
      menus: [],
      currentMenuPath: (window.location.hash.substring(1) || '').split('?')[0]
    }
  },
  beforeCreate () {
    this.$router.beforeEach(async (to, form, next) => {
      this.currentMenuPath = to.path
      next()
      if (!form || form.fullPath !== to.fullPath) {
        this.updateBadge()
      }
    })
    this.$root.$on('ft-fullsceen-event', (value) => {
      this.showMenu = !value
    })
    this.$root.$on('update-user-info', () => {
      this.userInfo = this._auth.userInfo
    })
    this.$root.$on('user-auth', (type) => {
      this.gotoAuthPath(type)
    })
    this.$root.$on('update-badge', () => {
      this.updateBadge()
    })
    this.$root.$on('orga-auth-tip', () => {
      this.$confirm('当前企业未认证，认证通过后可获得更多企业权益', '未认证提醒', {
        confirmButtonText: '去认证',
        cancelButtonText: '稍后验证',
        type: 'warning'
      }).then(() => {
        this.gotoAuthPath(this.userInfo?.current?.typeId)
      }).catch(() => {
      })
    })
  },
  async mounted () {
    this.updateBadge()
    if (
      this._auth.userInfo.current &&
      this._auth.userInfo.current.typeId === 2
    ) {
      const res = await axios.get(
        `${Server.path.user}/gateway/user/${this._auth.getUserId()}/orga/${
          this._auth.userInfo.current.id
        }/operation`
      )

      permission.setAuto({
        operations: res.data.result.map((opKid) => ({ opKid }))
      })
    } else {
      permission.setAuto({ operations: [] })
    }

    // 获取参与上线环境测试配置
    // const res = await axios.get('/knowledge/v1/sign/contract/active')
    // const data = res.data.result
    if (this._auth.userInfo.current.typeId === 2) {
      const res2 = await axios.get('/knowledge/v1/org/module/manage/open/status')
      // 合规评估系统：/assessment
      // 合同合规系统：/compliance-manage
      // 人事合规系统：/hr
      // 企业法律讲堂: /classroom
      // 法顾服务中心：/service
      const productPath = ['/assessment', '/compliance-manage', '/hr', '/classroom', '/service']
      const products = res2.data.result.reduce((pre, cur) => {
        return [...pre, productPath[cur.type - 1]]
      }, [])
      this.menus = MainService.getMenus(routerList, '', permission.hasPermission, this._auth.userInfo.current.typeId, this._auth.userInfo.current.id, productPath, products)
    } else {
      this.menus = MainService.getMenus(routerList, '', permission.hasPermission, this._auth.userInfo.current.typeId, this._auth.userInfo.current.id, [], [])
    }
    // 当路刷新页面时，页面的路径需要在菜单列表中
    const path = this.$route.path
    const index = this.flatMenus.findIndex((item) => item.path === path)
    const routerIndex = this.$router.options.routes[0].children.findIndex(
      (item) => item.path === path
    )
    if (index === -1 && routerIndex === -1) {
      const row = this.flatMenus.find(row => row.component)
      // 如果刷新时的路径不在菜单中，跳转换到首页
      this.$router.replace(row.path)
    }
    this.isShow = true
    this.$root.$on('update-unread-message-count', () => {
      this.updateUnreadMessageCount()
    })

    // setInterval(() => {
    //   this.$root.$emit('update-unread-message-count')
    // }, 2000)
    this.$root.$emit('update-unread-message-count')
    this.$root.$on('goto-user-auth', () => {
      this.gotoAuthPath(this.userInfo?.current?.typeId)
    })
  },
  computed: {
    switchBtnVisable () {
      return (this._auth.userInfo.orgas || []).some(row => row.id > 0)
    },
    flatMenus () {
      const flattenTree = (data) =>
        data.reduce(
          (pre, item) => [...pre, item, ...flattenTree(item.children)],
          []
        )
      const result = flattenTree(this.menus || [])
      return result
    },
    currentMenuId () {
      const menu = this.flatMenus.find(
        (row) => row.path === this.currentMenuPath
      )
      return menu ? menu.id : null
    },
    currentTopMenuId () {
      const path = this.currentMenuPath.split('?')[0].substring(1).split('/')
      let result = null
      if (path[0] || path[0] === '') {
        this.menus.forEach((row) => {
          if (row.path.toLowerCase() === ('/' + path[0]).toLowerCase()) {
            result = row.id
          }
        })
      }
      return result
    },
    currentTopMenuPath () {
      const path = this.currentMenuPath.split('?')[0].substring(1).split('/')
      let result = null
      if (path[0] || path[0] === '') {
        this.menus.forEach((row) => {
          if (row.path.toLowerCase() === ('/' + path[0]).toLowerCase()) {
            result = row.path
          }
        })
      }
      return result
    },
    submenus () {
      const result = []
      if (this.currentTopMenuPath) {
        return this.menus.find((row) => row.path === this.currentTopMenuPath)
          .children
      }
      return result
    },
    currentOrga () {
      if (this.userInfo?.current?.typeId === 2) {
        return this.userInfo.orgas.find(row => row.id === this.userInfo?.current?.id)
      } else {
        return null
      }
    },
    hasOtherIdentity () {
      const orgas = (this.userInfo.orgas || [])
      return orgas.some(r => r.id > 0)
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.showMenu = true
    next()
  },
  methods: {
    async updateBadge () {
      const res = await axios.get(`${Server.path.knowledge}/user/count/status/client`)
      this.badge.serviceCount = res.data.result.confirms || 0
      this.badge.sealReviewCount = res.data.result.seals || 0
      this.badge.contractReviewCount = res.data.result.agreements || 0
      this.badge.contractSignCount = res.data.result.waitSign || 0
      const res1 = await axios.get('/knowledge/v1/employee/bubbling')
      this.badge.waitConfirmation = res1.data.result.waitConfirmation || 0
      this.badge.waitEntry = res1.data.result.waitEntry || 0
      this.badge.waitResign = res1.data.result.waitResign || 0
    },
    gotoMessage () {
      this.$router.push('/system/account/message')
    },
    async updateUnreadMessageCount () {
      const res = await axios.get(`${Server.path.knowledge}/msg/message/unRead`)
      this.unreadMessageCount = res.data.result.unread
    },
    gotoRoleSelect () {
      const url = this.$router.resolve({ path: this.$route.path })
      window.location.href = `//sso.ftling.com/?url=${encodeURIComponent(window.location.origin + window.location.pathname + url.href)}#/select`
    },
    findFirstMenuPath (menus) {
      for (let i = 0; i < menus.length; i++) {
        if (menus[i].component) {
          return menus[i].path
        } else if (menus[i].children) {
          const path = this.findFirstMenuPath(menus[i].children)
          if (path) {
            return path
          }
        }
      }
      return null
    },
    goto (menu) {
      if (menu.link) {
        const ele = document.createElement('a')
        ele.href = menu.link
        ele.target = menu.target || '_blank'
        ele.click()
        ele.remove()
        return false
      }
      if (!menu.component && !menu.redirect && (!menu.children || menu.children.length === 0)) return false
      const findLastMenu = (parent, menu) => {
        if (menu.children && menu.children.length > 0) {
          return findLastMenu(menu, menu.children[0])
        } else {
          if (menu.component) {
            return menu.path
          } else if (parent) {
            const menu = parent.children.filter(row => row.component)[0]
            if (menu) {
              return menu.path
            } else {
              return null
            }
          } else {
            return null
          }
        }
      }
      const path = findLastMenu(null, menu)
      if (path === this.currentMenuPath) return false
      this.$router.push(path)
    },
    async logout () {
      this._auth.clearToken()
      this._auth.userInfo = null
      window.sessionStorage.clear()
      const url = this.$router.resolve({ path: this.$route.path })
      window.location.href = `//sso.ftling.com?url=${encodeURIComponent(
        window.location.origin + window.location.pathname + url.href
      )}`
    },
    gotoAuthPath (type) {
      switch (type) {
        // 个人
        case 1:
          this.$router.push(`/system/account/secure?auth=${new Date().getTime()}`)
          break
        // 企业
        case 2:
          if (this.hasPermission('bf087a0b-e28a-4796-9573-ebebb1d8789e')) {
            this.$router.push(`/system/account/orga-account?auth=${new Date().getTime()}`)
          } else {
            this.$message({
              type: 'info',
              message: '请联系企业管理员进行企业认证，获取更多企业权益'
            })
          }
          break
      }
    },
    gotoHelp () {
      window.open('https://txc.qq.com/products/600180')
    }
  }
}
</script>
<style lang="less" scoped>
.ft-frame {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f7f7fb;
  .header {
    background: linear-gradient(270deg, #3f61f0 1%, #3350cb);
    display: flex;
    flex-direction: row;
    padding: 5px;
    box-sizing: border-box;
    font-size: 14px;
    .logo {
      background-image: url(@/assets/logo-white.png);
      background-size: 100%;
      height: 48px;
      width: 126px;
      flex: 0 0 126px;
    }
    .nav {
      flex: 1 auto;
      margin-left: 40px;
      white-space: nowrap;
      overflow: hidden;
      .nav-but {
        display: inline-block;
        height: 50px;
        width: 100px;
        flex: 0 100px;
        background-color: transparent;
        border-radius: 4px;
        text-align: center;
        line-height: 50px;
        color: #fff;
        margin-right: 10px;
        cursor: pointer;
        &:hover {
          background: #2135aa;
        }
        &.active {
          background: #2135aa;
        }
      }
    }
    .tools {
      flex: 0 100px;
      display: flex;
      flex-direction: row;
      color: #fff;
      padding: 0 10px;
      .tools-btn {
        text-align: center;
        line-height: 50px;
        // margin-right: 20px;
        position: relative;
        .company-name {
          display: inline-block;
          white-space: nowrap;
          background-color:#5876EB;
          height: 32px;
          line-height: 32px;
          padding: 0px 10px;
          cursor: pointer;
        }

        &+.tools-btn {
          margin-left: 20px;
        }
        .auth-icon {
          position: absolute;
          bottom: 4px;
          right: -2px;
          width: 14px;
        }
        .message {
          font-size: 22px;
          vertical-align: middle;
          cursor: pointer;
        }
        .split {
          display: inline-block;
          width: 1px;
          height: 20px;
          background-color: #fff;
          vertical-align: middle;
        }
        .avater {
          width: 30px;
          height: 30px;
          border-radius: 30px;
          vertical-align: middle;
        }
        .badge{
          display: inline;
          font-size: 16px;

        }
        &.help {

          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          img {
            width: 20px;
            height: 20px;
          }
          span {
            width: 30px;
            margin-left: 5px
          }
        }
      }
    }
  }
  .main {
    flex: 1 auto;
    display: flex;
    flex-direction: row;
    height: 100%;
    .menus {
      padding-top: 10px;
      flex: 0 0 200px;
      border-right: 1px solid #e6e6e6;
      background-color: #fff;
      .menu-item {
        user-select: none;
        cursor: pointer;
        margin-top: 10px;
        font-size: 14px;
        color: #2a2b2f;
        padding: 10px 10px 10px 20px;
        display: flex;
        flex-direction: row;
        font-weight: 400;
        border-right: 3px solid transparent;
        &:hover {
          background: #f0f1f6;
          border-right: 3px solid #3350cb;
          .label {
            font-weight: 600;
            color: #3350cb;
          }
        }
        &.active {
          background: #f0f1f6;
          border-right: 3px solid #3350cb;
          .label {
            font-weight: 600;
            color: #3350cb;
          }
        }
        .icon {
          flex: 0 20px;
          line-height: 20px;
          cursor: pointer;
        }
        .label {
          flex: 1 auto;
          line-height: 20px;
          cursor: pointer;
        }
        .expand {
          flex: 0 20px;
          line-height: 20px;
          cursor: pointer;
        }
        .menu-item-icon {
          font-size: 14px;
          margin-right: 5px;
          margin-top: 3px;
        }
        .badge {
          background-color: #f56c6c;
          border-radius: 10px;
          color: #fff;
          display: inline-block;
          font-size: 12px;
          height: 18px;
          line-height: 18px;
          padding: 0 6px;
          text-align: center;
          white-space: nowrap;
          border: 1px solid #fff;
          &.zero {
            background-color: #797979;
          }
        }
      }
    }
    .content {
      flex: 1 auto;
      background-color: #f7f7fb;
      padding: 20px;
      display: flex;
      flex-direction: column;
      // overflow: hidden;
      overflow-y: auto;
      .tip {
        background: #fef1f0;
        border: 1px solid #ffb9b5;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #2a2b2f;
        line-height: 20px;
        padding: 10px;
        margin-bottom: 20px;
        i {
          color: #ff2b2b;
          margin-right: 6px;
          font-size: 16px;
        }
      }
      .inside {
        flex: 1 auto;
        height: 0;
      }
    }
  }
}
</style>
<style lang="less">
.frame-popup-menu {
  padding: 0px !important;
  width: 310px !important;
  &.w {
    width: 220px !important;
  }
  .top-menus {
    padding: 5px;
    .frame-popup-menu-info {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      margin-bottom: 25px;
      .avater {
        margin-top: 40px;
        position: relative;
        .auth {
          position: absolute;
          bottom: -10px;
          font-size: 10px;
          left: 3px;
          background: #e8e6ef;
          border-radius: 2px;
          color: #96979f;
          padding: 3px;
          i {
            font-size: 10px;
          }
          &.yes {
            color: #4cbe9f;
            background: #e4f9f4;
          }
        }
        img {
          width: 64px;
          height: 64px;
          border-radius: 50%;
        }
      }
      .name {
        margin-top: 18px;
        font-size: 18px;
        font-weight: 600;
        color: #2a2b2f;
        text-align: center;
        line-height: 22px;
      }
      .company {
        margin-top: 8px;
        font-size: 14px;
        text-align: center;
        color: #9597a1;
        line-height: 22px;
      }
    }
    .frame-popup-menu-item {
      font-size: 14px;
      font-weight: 400;
      color: #2a2b2f;
      margin: 0px 5px;
      padding: 14px 25px;
      cursor: pointer;
      border-top: 1px solid #e6e7ef;
      i {
        font-size: 14px;
        margin-right: 10px;
      }
      &:hover {
        background-color: #f0f1f6;
      }
    }
  }
  .kefu {
    text-align: center;
    padding: 20px;
    .kefu-1 {
      height: 22px;
      font-size: 16px;
      font-weight: 400;
      color: #2c3249;
      line-height: 22px;
    }
    .kefu-2 {
      margin-top: 8px;
      height: 28px;
      font-size: 20px;
      font-weight: 600;
      color: #3350cb;
      line-height: 28px;
    }
    .kefu-split {
      margin-top: 20px;
      margin-left: 20ppx;
      margin-right: 20ppx;
      height: 1px;
      border-bottom: 1px dashed #e8e8e8;
    }
    .kefu-3 {
      margin-top: 20px;
      height: 22px;
      font-size: 16px;
      font-weight: 400;
      color: #2c3249;
      line-height: 22px;
    }
    .kefu-4 {
      margin-top: 10px;
      height: 17px;
      font-size: 12px;
      font-weight: 400;
      color: #9597a1;
      line-height: 17px
    }
    .kefu-5 {
      img {
        width: 100%;
      }
    }
  }
  .auth-info {
    display: inline-block;
    font-size: 10px;
    background: #e8e6ef;
    border-radius: 2px;
    color: #96979f;
    padding: 3px;
    .icon {
      font-size: 10px;
      margin-right: 0px !important;
    }
    &.yes {
      color: #4cbe9f;
      background: #e4f9f4;
    }
  }
}
</style>
