export default [
  {
    path: '/hr',
    redirect: '/hr/overview',
    meta: {
      id: '3ee0222e-ff13-424e-a95d-c449058ac8b8',
      icon: '',
      name: 'hr',
      title: '人事合规',
      type: [2],
      apis: []
    },
    children: [
      {
        path: '/overview',
        meta: {
          id: '4d4156ac-dbe3-4908-950e-455d280d9697',
          icon: 'icon-zonglanicon2',
          name: 'overview',
          title: '总览',
          iconColor: '#507BFD',
          type: [2],
          apis: []
        },
        component: () => import('@/webapp/hr/overview.vue')
      },
      {
        path: '/roster',
        meta: {
          id: 'ca682d59-3d11-472d-8c6e-0322b0ae140f',
          icon: 'icon-zonglanicon2',
          name: 'roster',
          title: '花名册',
          iconColor: '#507BFD',
          type: [2],
          apis: []
        },
        component: () => import('@/webapp/hr/roster.vue')
      },
      {
        path: '/institution',
        meta: {
          id: '94bbd4e1-d40f-4c37-9b2b-c87c673bc282',
          icon: 'icon-zonglanicon',
          name: 'institution',
          title: '人事制度',
          iconColor: '#507BFD',
          type: [2],
          apis: []
        },
        component: () => import('@/webapp/hr/institution.vue')
      },
      {
        path: '/employee-manual',
        meta: {
          id: '',
          icon: 'icon-zonglanicon',
          name: 'employeeManual',
          title: '员工手册',
          iconColor: '#507BFD',
          type: [2],
          apis: [],
          hide: true
        },
        component: () => import('@/webapp/hr/employee-manual.vue')
      },
      {
        path: '/contract-list',
        meta: {
          id: '98bf39ca-ade4-4011-ad9f-2f563d2bad19',
          icon: 'icon-hetongheguiicon',
          name: 'contract-list',
          title: '人事合同',
          iconColor: '#507BFD',
          type: [2],
          apis: []
        },
        component: () => import('@/webapp/hr/contract-list.vue')
      },
      {
        path: '/manage',
        meta: {
          id: '17e43ee4-59e2-4dee-8d80-0a7065f8703f',
          icon: 'icon-fawufuwuicon',
          name: 'manage',
          title: '人事管理',
          iconColor: 'rgb(69, 172, 246)',
          type: [2],
          apis: []
        },
        children: [
          {
            path: '/entry',
            meta: {
              id: '8e12cc9e-8cca-4d55-9e6d-e4e6accea9e7',
              name: 'entry',
              title: '入职管理',
              type: [2],
              apis: [],
              badge: 'waitEntry'
            },
            component: () => import('@/webapp/hr/manage/entry.vue')
          },
          {
            path: '/regular',
            meta: {
              id: 'e4a6eafc-f679-447b-9596-c8a45de99ac5',
              name: 'regular',
              title: '转正管理',
              type: [2],
              apis: [],
              badge: 'waitConfirmation'
            },
            component: () => import('@/webapp/hr/manage/regular.vue')
          },
          {
            path: '/dimission',
            meta: {
              id: '53b3c837-ead7-4e5b-9b8b-55fda070b4cc',
              name: 'dimission',
              title: '离职管理',
              type: [2],
              apis: [],
              badge: 'waitResign'
            },
            component: () => import('@/webapp/hr/manage/dimission.vue')
          },
          {
            path: '/contract',
            meta: {
              id: '80d951c2-8016-446b-a161-c2a05d36ad57',
              name: 'contract',
              title: '合同管理',
              type: [2],
              apis: []
            },
            component: () => import('@/webapp/hr/manage/contract.vue')
          }
        ]
      },
      {
        path: '/setting',
        meta: {
          id: '19d85b3b-afc3-4b57-a55b-1334e959b9c8',
          icon: 'icon-zonglanicon2',
          name: 'setting',
          title: '设置',
          iconColor: '#507BFD',
          type: [2]
        },
        children: [
          {
            path: '/employee-field',
            meta: {
              id: '07636bfd-1f44-4b6a-b78b-85201e7b9388',
              name: 'employee-field',
              title: '员工字段',
              type: [2]
            },
            component: () => import('@/webapp/hr/setting/employee-field')
          }
        ]
      }
    ]
  }
]
