// 首页
const overview = () => import('@/webapp/home/overview.vue')
const courseStudy = () => import('@/webapp/home/course/study.vue')
const coursePurchase = () => import('@/webapp/home/course/purchase.vue')

export default [
  {
    path: '/home',
    redirect: '/home/manage',
    meta: {
      id: 'aba96860-3227-4566-9644-1f00ca7d1b78',
      icon: '',
      name: 'home',
      title: '首页',
      type: [0],
      apis: []
    },
    children: [
      {
        path: '/manage',
        meta: {
          id: '',
          icon: 'icon-zonglanicon2',
          iconColor: '#507BFD',
          name: 'manage',
          title: '工作台',
          type: [2],
          apis: []
        },
        component: () => import('@/webapp/home/manage.vue')
      },
      {
        path: '/order',
        meta: {
          id: '0f63376a-e9ac-49d5-9b99-c142edcbd42f',
          icon: 'icon-dingdanyufuwuicon',
          iconColor: '#ff7172',
          name: 'order',
          title: '订单与服务',
          type: [2]
        },
        children: [
          {
            path: '/manage',
            meta: {
              id: 'f8c98224-a3e7-4078-96b7-75859887ff33',
              icon: '',
              name: 'manage',
              title: '订单管理',
              type: [2]
            },
            component: () => import('@/webapp/service/order.vue')
          },
          {
            path: '/purchased',
            meta: {
              id: '4cf9eb71-7ad5-453b-9085-37b8718f1d7f',
              icon: '',
              name: 'purchased',
              title: '已购服务',
              type: [2]
            },
            component: () => import('@/webapp/service/purchased.vue')
          }
        ]
      },
      {
        path: '/manage',
        meta: {
          id: '',
          icon: 'icon-zonglanicon',
          name: 'manage',
          title: '总览',
          iconColor: '#507BFD',
          type: [1],
          apis: []
        },
        component: overview
      },
      {
        path: '/ftzs',
        meta: {
          id: '',
          icon: 'icon-pufaxuefaicon1',
          title: '法图知识',
          type: [1],
          link: '//mall.ftling.com/',
          target: '_self',
          iconColor: '#FF9640',
          apis: []
        }
      },
      {
        path: '/consult',
        meta: {
          id: '',
          icon: 'el-icon-s-comment',
          title: '法律咨询',
          iconColor: '#507BFD',
          type: [1],
          link: '//zx.ftling.com/#/pcClient/user/zx',
          apis: []
        }
      },
      {
        path: '/course',
        meta: {
          id: '',
          icon: 'icon-pufaxuefaicon1',
          title: '法图讲堂',
          iconColor: '#FF7172',
          type: [1],
          apis: []
        },
        children: [
          {
            path: '/study',
            meta: {
              id: '',
              type: [1],
              title: '学习中心'
            },
            component: courseStudy
          },
          {
            path: '/purchase',
            meta: {
              id: '',
              type: [1],
              title: '已购课程'
            },
            component: coursePurchase
          }
        ]
      },
      {
        path: '/collect',
        meta: {
          id: '',
          icon: 'icon-shoucangjia1',
          name: 'collect',
          title: '收藏夹',
          type: [1],
          iconColor: '#FF7172',
          apis: [
            'get:/knowledge/v1/user/collect/list',
            'post:/knowledge/v1/user/up/{id}'
          ]
        },
        component: () => import('@/webapp/home/homeCollect.vue')
      }
    ]
  }
]
